<template>
  <div class="second-classfition dark_bg">
    <Heador />
    <category />
    <!-- <secondSlider /> -->
    <!-- <classList /> -->
    <div class='gap'></div>
    <router-view></router-view>
  </div>
</template>

<script>
import Heador from "./secondheader";
import Category from "./categoryFirst";
import classList from "./classlist";
// import secondSlider from "./secondslider";
export default {
  data() {
    return {};
  },
  components: {
    Heador,
    classList,
    // secondSlider,
    Category
  }
};
</script>

<style lang='less' scoped>
.second-classfition {
  width: 100%;
  box-sizing: border-box;
  padding-top: .92rem /* 46/50 */;
  min-height: 100vh;
  background-color: #fff;
}
.gap{
  width: 100%;
  height: .2rem;
  
background: #F6F6F9;
}
</style>
