<template>
  <!-- 二级分类页面的分类展示 -->
  <van-tabs
    v-model="firstId"
    :swipe-threshold="8"
    color="#d84b41"
    title-active-color="#fff"
    title-inactive-color="rgba(255, 255, 255, 0.9)"
    background="#d84b41"
    line-width="0"
    :border="false"
    :ellipsis="false"
    @click="clickFirst"
    class="dark_vant"
  >
    <van-tab
      v-for="item in categoryList"
      :title="item.title"
      :key="item.id"
      :name="item.id"
      class="dark_bg"
    >
      <swiper :options="swiperOption">
  
        <swiper-slide v-for="(page, index) of pages" :key="index" :style="{'min-height': pages[1] ? '4rem' : '0'}"> 
          <div
            class="iconlist"
            v-for="val of page"
            :key="val.id"
            @click="clickSecond(item.id, val.id)"
          >
            <div class="icon-img">
              <img class="icon-img-content" v-lazy="val.picUrl" />
            </div>
            <p class="icon-desc" :class="{ active: activeIndex == val.id }">
              {{ val.title }}
            </p>
          </div>
        <!-- 小圆点 -->
        <div v-if="pages[1]" class="swiper-pagination page-fy"></div>
         </swiper-slide>
      </swiper>
   </van-tab>
  </van-tabs> 
</template>

<script>
import { Tab, Tabs } from "vant";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  data() {
    return {
      categoryList: [], // 分类全部数据
      firstId: null,
      activeList: [], // 当前选中的
      activeIndex: null,
      swiperOption: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        initialSlide: 0,
        autoplay: false,
      },
    };
  },
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [swiper.name]: swiper,
    [swiperSlide.name]: swiperSlide,
  },
  created() {
    this.firstId = Number(this.$route.params.id);
    // console.log("this.$route.params", this.$route.params);
    this.getCategory();
  },
  
  computed: {
    // 处理获取的数据
    pages() {
      const pages = []; // pages是为二维数组
      // console.log("this.activeList", this.activeList);
      this.activeList.forEach((item, index) => {
        const page = Math.floor(index / 10); // page本质是0.1.2.3分别表示第1,2,3,4页
        if (!pages[page]) {
          pages[page] = [];
        }
        pages[page].push(item);
      });
      console.log("pages****", pages);
      return pages;
    },
    // 实例化swiper
    mySwiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  methods: {
    // 获取分类并对默认显示做处理
    // getCategory() {
    //   this.$api.home.getGoodsCategoryAll({}).then(res => {
    //     if (res.code === 100) {
    //       this.categoryList = res.returnValue;
    //       this.categoryList = [
    //         { name: "首页", id: "home", sub: [] },
    //         ...this.categoryList
    //       ];
    //       this.categoryList.forEach(item => {
    //         if (this.firstId === item.id) {
    //           this.activeList = item.sub;
    //           this.activeIndex = this.$route.params.categoryId;
    //           return false;
    //         }
    //       });
    //     }
    //   });
    // },
    getCategory() {
      this.$api.home.getGoodsCategoryAll({ type: "1" }).then((res) => {
        if (res.errno === 200) {
          this.categoryList = res.data;
          this.categoryList = [
            { title: "首页", id: "home", sub: [] },
            ...this.categoryList,
          ];
          // debugger
          this.categoryList.forEach((item) => {
            //  console.log('this.firstId 0000', this.firstId);
            //  console.log('item.id 0000', item.id);
            if (this.firstId == item.id) {
              this.activeList = item.childrenList;
              // console.log("this.activeList2222222", this.activeList);
              this.activeIndex = this.$route.params.categoryId;
              return false;
            }
          });
        }
      });
    },
    // 操作一级分类列表
    clickFirst(firstId, title) {
      // console.log('操作一级分类列表', {firstId, title});
      if (firstId == "home") {
        this.$router.replace("/");
        return false;
      }
      this.categoryList.forEach((item) => {
        if (item.id == firstId) {
          if (!item.childrenList) {
            this.activeList = [];
            let secondId = item.id;
            this.activeIndex = secondId;
            this.$router.replace(`/secondclass/${firstId}/goods/${secondId}`);
            return false;
          }
          this.activeList = item.childrenList;
          let secondId = item.childrenList[0].id;
          this.activeIndex = secondId;
          this.$router.replace(`/secondclass/${firstId}/goods/${secondId}`);
          return false;
        }
      });
    },
    // 操作二级分类
    clickSecond(firstId, secondId) {
      this.activeIndex = secondId;
      this.$router.replace(`/secondclass/${firstId}/goods/${secondId}`);
    },
  },
};
</script>

<style lang="less" scoped>
.van-tabs {
  /deep/ .van-tab--active {
    font-size: 0.36rem /* 18/50 */;
  }
  /deep/ .van-tabs__content {
    background-color: #fff;
    box-sizing: border-box;
    // margin: 0.32rem /* 16/50 */ 0;
    .swiper-container {
      margin-top: .4rem;
      padding-bottom: .5rem;
      .swiper-wrapper {
        .swiper-slide {
          display: flex;
          flex-wrap: wrap;
          .iconlist {
            width: 20%;
            text-align: center;
            margin: 0 0 0.24rem /* 12/50 */;
            .icon-img {
              img {
                width: 70%;
              }
            }
            .icon-desc {
              font-size: 0.24rem /* 12/50 */;
              color: #999696;
              letter-spacing: 1px;
              margin: 0.16rem /* 8/50 */ 0 0;
              &.active {
                color: @category_title_text_color;
              }
            }
          }
        }
      }
      .swiper-pagination-bullets {
        bottom: -0rem;
      }
      & /deep/ .swiper-pagination-bullet-active {
        background: #ff03005c;
      }
    }
  }
}
/deep/ .van-tabs__nav--line{
  height: 0;
  padding-bottom: 0;
}
/deep/ .van-tabs__wrap{
  height: 0;
}
.page-fy{
  position: absolute;
  bottom: -.2rem !important;
  left: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: .6rem;
  transform: translateX(-50%);
}

.swiper-pagination-bullet{
 background-color: #AEAEAE !important;
  opacity: 0.6 !important;
}
/deep/.swiper-pagination-bullet-active{
     color: #FF6468;
  opacity: 1 !important;

  width: .40rem;
  height: .12rem;
  background-color: #FF6468 !important;
  box-shadow: 0px 0px .1rem 0px rgba(55, 100, 255, 0.1);
  border-radius: .6rem;
}

</style>