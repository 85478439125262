<template>
  <!--分类列表  -->
  <div class="classbox-second">
    <div class="classlist">
      <swiper :options="swiperOption" ref="mySwiper">
        <swiper-slide v-for="(page, index) of pages" :key="index">
          <div
            class="iconlist"
            v-for="item of page"
            :key="item.id"
            @click="clickActive(item.id)"
          >
            <div class="icon-img">
              <img class="icon-img-content" v-lazy="item.imageURL" />
            </div>
            <p class="icon-desc" :class="{ active: activeIndex == item.id }">
              {{ item.categoryName }}
            </p>
          </div>
        </swiper-slide>
        <!-- 小圆点 -->
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  data() {
    return {
      classList: [],
      activeIndex: -1,
      currentPage: 1,
      swiperOption: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        initialSlide: 0,
        autoplay: false,
      },
    };
  },
  components: {
    [swiper.name]: swiper,
    [swiperSlide.name]: swiperSlide,
  },
  created() {
    this.getSecondClassTab(this.$route.params.id);
  },
  computed: {
    // 处理获取的数据
    pages() {
      const pages = []; // pages是为二维数组
      this.classList.forEach((item, index) => {
        const page = Math.floor(index / 8); // page本质是0.1.2.3分别表示第1,2,3,4页
        if (!pages[page]) {
          pages[page] = [];
        }
        pages[page].push(item);
      });
      return pages;
    },
    // 实例化swiper
    mySwiper() {
      return this.$refs.mySwiper.swiper;
    },
  },

  methods: {
    // 二级分类当前选中状态
    chengeActive() {
      let categoryId = this.$route.params.categoryId;
      if (categoryId != "0") {
        this.activeIndex = this.$route.params.categoryId;
      } else {
        // 从首页进来的默认显示第一个二级分类商品
        let parentId = this.$route.params.id;
        this.activeIndex = this.classList[0].id;
        this.$router.replace(
          `/secondclass/${parentId}/goods/${this.activeIndex}`
        );
      }
    },
    // 更新当前页码
    changePage() {
      let categoryId = this.$route.params.categoryId;
      var pageIndex;
      this.classList.forEach((item, index) => {
        if (categoryId == item.id) {
          pageIndex = Math.floor(index / 8);
          // 跳转到指定的页面
          this.mySwiper.slideTo(pageIndex, 200, false);
          return false;
        }
      });
    },
    // 点击二级分类
    clickActive(categoryId) {
      this.activeIndex = categoryId;
      let parentId = this.$route.params.id;
      this.$router.replace(`/secondclass/${parentId}/goods/${categoryId}`);
    },
    // 获取二级分类
    getSecondClassTab(parentId) {
      this.$server
        .secondClassListAddress({
          parentId,
        })
        .then((res) => {
          if (res.code == 100) {
            this.classList = res.returnValue;
            // 更新当前选中状态值
            this.chengeActive();
            // 更新当前页码
            this.changePage();
          }
        });
    },
  },
};
</script>

<style lang='less' scoped>
@red: #ca3e3d;
.classbox-second {
  width: 100%;
  box-sizing: border-box;
  padding: 0.6rem 0.6rem 0.9rem;
  .classlist {
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    padding: 0.8rem 0 0.4rem;
    border-radius: 0.2rem;
    .swiper-container {
      .swiper-wrapper {
        .swiper-slide {
          display: flex;
          flex-wrap: wrap;
          .iconlist {
            width: 25%;
            text-align: center;
            margin: 0 0 0.8rem;
            .icon-img {
              img {
                width: 70%;
              }
            }
            .icon-desc {
              font-size: 0.8rem;
              color: #999696;
              letter-spacing: 1px;
              margin: 0.5rem 0 0;
              &.active {
                color: #c83f3f;
              }
            }
          }
        }
      }
      .swiper-pagination-bullets {
        bottom: -0rem;
      }
      & /deep/ .swiper-pagination-bullet-active {
        background: #ff03005c;
      }
    }
  }
}
</style>

